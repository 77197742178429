<script setup lang="ts">
import dayjs from "dayjs";

const currentYear = dayjs().year();
</script>

<template>
  <div class="flex justify-center w-full px-3 py-8">
    <div class="text-[10px] font-medium text-center">
      © 2018-{{ currentYear }}, Apian Systems, LLC dba Notary.io. All rights
      reserved.<br />
      Here's our
      <NuxtLink class="text-[10px]" to="/terms-of-service">terms</NuxtLink> and
      <NuxtLink class="text-[10px]" to="/">policies</NuxtLink>
    </div>
  </div>
</template>
